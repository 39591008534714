import React from "react";
import "./App.css";
import setAuthToken from "./utils/setAuthToken";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import PrivateRoute from "./components/routing/PrivateRoute";
import Home from "./components/pages/App/Home";
import About from "./components/pages/Static/About";
import Project from "./components/pages/Static/Project";
import Services from "./components/pages/Static/Services";
import Contact from "./components/pages/Static/Contact";
import NotFound from "./components/pages/Static/NotFound";
import Login from "./components/auth/Login";
import Register from "./components/auth/Register";
import LandingPage from "./components/pages/Static/LandingPage";
import Navbar from "./components/partials/Navbar";
import Footer from "./components/partials/Footer";
import Alerts from "./components/partials/Alerts";

if (localStorage.token) {
  setAuthToken(localStorage.token);
}

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <>
          <Alerts />
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <Route exact path="/aboutus" component={About} />
            <Route exact path="/projects" component={Project} />
            <Route exact path="/services" component={Services} />
            <Route exact path="/contact" component={Contact} />
            <Route exact path="/register" component={Register} />
            <Route exact path="/login" component={Login} />
            <PrivateRoute exact path="/home" component={Home} />
            <Route component={NotFound} />
          </Switch>
        </>
        <Footer></Footer>
      </Router>
    </>
  );
}

export default App;
