import React from "react";
import {
  BriefcaseIcon,
  CubeTransparentIcon,
  GlobeAltIcon,
  LightBulbIcon,
  PresentationChartLineIcon,
  ScaleIcon,
  SparklesIcon,
  SpeakerphoneIcon,
  TrendingUpIcon,
} from "@heroicons/react/outline";
import amy from "./images/aboutus/aboutus-amy.png";
import obam from "./images/aboutus/aboutus-obam.jpg";
import cyprian from "./images/aboutus/aboutus-cyprian.png";

import youthClinicImage from './images/projects/youth-clinic.jpg';
import summitImage from './images/projects/summit.jpg';
import africanSportsBusinessForumImage from './images/projects/african-sports-business-forum.jpg';
import africanSportsFestivalImage from './images/projects/african-sports-festival.jpg';
import UrbanCupImage from './images/projects/urbancup.jpg';
import sportsLeadershipImage from './images/projects/sportsworkshop.jpg'

import logo1 from './images/logos/logo1.png';
import logo2 from './images/logos/logo2.png';
import logo3 from './images/logos/logo3.jpg';
import logo4 from './images/logos/logo4.png';
import logo5 from './images/logos/logo5.png';
import logo6 from './images/logos/logo6.jpg';
import logo7 from './images/logos/logo7.jpeg';
import logo9 from './images/logos/logo9.png';
import logo10 from './images/logos/logo10.png';

export const staticContent = {
  Navbar: {
    navigation: [
      { name: "About us", href: "/aboutus", current: true },
      { name: "Projects", href: "/projects" },
      { name: "Services", href: "/services" },
      { name: "Contact", href: "/contact" },
    ],
  },
  logo: [logo1, logo2, logo3, logo4, logo5, logo6, logo7, logo9, logo10],
  Footer: {
    navigation: {
      main: [
        { name: "Home", href: "/" },
        { name: "About us", href: "/aboutus" },
        { name: "Projects", href: "/projects" },
        { name: "Services", href: "/services" },
        { name: "Contact", href: "/contact" },
      ],
      social: [
        {
          name: "Facebook",
          href: "https://facebook.com/asnkenya",
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M22 12c0-5.523-4.477-10-10-10S2 6.477 2 12c0 4.991 3.657 9.128 8.438 9.878v-6.987h-2.54V12h2.54V9.797c0-2.506 1.492-3.89 3.777-3.89 1.094 0 2.238.195 2.238.195v2.46h-1.26c-1.243 0-1.63.771-1.63 1.562V12h2.773l-.443 2.89h-2.33v6.988C18.343 21.128 22 16.991 22 12z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
        {
          name: "Instagram",
          href: "https://www.instagram.com/african_sports_network/",
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M12.315 2c2.43 0 2.784.013 3.808.06 1.064.049 1.791.218 2.427.465a4.902 4.902 0 011.772 1.153 4.902 4.902 0 011.153 1.772c.247.636.416 1.363.465 2.427.048 1.067.06 1.407.06 4.123v.08c0 2.643-.012 2.987-.06 4.043-.049 1.064-.218 1.791-.465 2.427a4.902 4.902 0 01-1.153 1.772 4.902 4.902 0 01-1.772 1.153c-.636.247-1.363.416-2.427.465-1.067.048-1.407.06-4.123.06h-.08c-2.643 0-2.987-.012-4.043-.06-1.064-.049-1.791-.218-2.427-.465a4.902 4.902 0 01-1.772-1.153 4.902 4.902 0 01-1.153-1.772c-.247-.636-.416-1.363-.465-2.427-.047-1.024-.06-1.379-.06-3.808v-.63c0-2.43.013-2.784.06-3.808.049-1.064.218-1.791.465-2.427a4.902 4.902 0 011.153-1.772A4.902 4.902 0 015.45 2.525c.636-.247 1.363-.416 2.427-.465C8.901 2.013 9.256 2 11.685 2h.63zm-.081 1.802h-.468c-2.456 0-2.784.011-3.807.058-.975.045-1.504.207-1.857.344-.467.182-.8.398-1.15.748-.35.35-.566.683-.748 1.15-.137.353-.3.882-.344 1.857-.047 1.023-.058 1.351-.058 3.807v.468c0 2.456.011 2.784.058 3.807.045.975.207 1.504.344 1.857.182.466.399.8.748 1.15.35.35.683.566 1.15.748.353.137.882.3 1.857.344 1.054.048 1.37.058 4.041.058h.08c2.597 0 2.917-.01 3.96-.058.976-.045 1.505-.207 1.858-.344.466-.182.8-.398 1.15-.748.35-.35.566-.683.748-1.15.137-.353.3-.882.344-1.857.048-1.055.058-1.37.058-4.041v-.08c0-2.597-.01-2.917-.058-3.96-.045-.976-.207-1.505-.344-1.858a3.097 3.097 0 00-.748-1.15 3.098 3.098 0 00-1.15-.748c-.353-.137-.882-.3-1.857-.344-1.023-.047-1.351-.058-3.807-.058zM12 6.865a5.135 5.135 0 110 10.27 5.135 5.135 0 010-10.27zm0 1.802a3.333 3.333 0 100 6.666 3.333 3.333 0 000-6.666zm5.338-3.205a1.2 1.2 0 110 2.4 1.2 1.2 0 010-2.4z"
                clipRule="evenodd"
              />
            </svg>
          ),
        },
        {
          name: "Twitter",
          href: "https://twitter.com/asn_kenya",
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path d="M8.29 20.251c7.547 0 11.675-6.253 11.675-11.675 0-.178 0-.355-.012-.53A8.348 8.348 0 0022 5.92a8.19 8.19 0 01-2.357.646 4.118 4.118 0 001.804-2.27 8.224 8.224 0 01-2.605.996 4.107 4.107 0 00-6.993 3.743 11.65 11.65 0 01-8.457-4.287 4.106 4.106 0 001.27 5.477A4.072 4.072 0 012.8 9.713v.052a4.105 4.105 0 003.292 4.022 4.095 4.095 0 01-1.853.07 4.108 4.108 0 003.834 2.85A8.233 8.233 0 012 18.407a11.616 11.616 0 006.29 1.84" />
            </svg>
          ),
        },
        {
          name: "LinkedIn",
          href: "https://www.linkedin.com/company/africansportsnetwork/",
          icon: (props) => (
            <svg fill="currentColor" viewBox="0 0 24 24" {...props}>
              <path
                fillRule="evenodd"
                d="M16.338 16.338H13.67V12.16c0-.995-.017-2.277-1.387-2.277-1.39 0-1.601 1.086-1.601 2.207v4.248H8.014v-8.59h2.559v1.174h.037c.356-.675 1.227-1.387 2.526-1.387 2.703 0 3.203 1.778 3.203 4.092v4.711zM5.005 6.575a1.548 1.548 0 11-.003-3.096 1.548 1.548 0 01.003 3.096zm-1.337 9.763H6.34v-8.59H3.667v8.59zM17.668 1H2.328C1.595 1 1 1.581 1 2.298v15.403C1 18.418 1.595 19 2.328 19h15.34c.734 0 1.332-.582 1.332-1.299V2.298C19 1.581 18.402 1 17.668 1z"
                clipRule="evenodd"
              />{" "}
            </svg>
          ),
        },
      ],
    },
  },
  home: {
    stats: [{ label: "Founded", value: "2018" }],
  },
  aboutus: {
    transferFeatures: [
      {
        id: 1,
        name: "To be Africa’s leading sports database bridging the gap between talent and opportunity.",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: CubeTransparentIcon,
      },
      {
        id: 2,
        name: "Creating opportunities to identify and address social challenges through education.",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: ScaleIcon,
      },
      {
        id: 3,
        name: "Sensitization and awareness on the importance of sports entrepreneurship.",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: LightBulbIcon,
      },
      {
        id: 4,
        name: "Job creation for youth through sports enterprise.",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: BriefcaseIcon,
      },
      {
        id: 5,
        name: "Influencing policy in the sports industry.",
        description:
          "Lorem ipsum, dolor sit amet consectetur adipisicing elit. Maiores impedit perferendis suscipit eaque, iste dolor cupiditate blanditiis ratione.",
        icon: GlobeAltIcon,
      },
    ],
    people: [
      {
        name: "Amy Wanday",
        role: "Founder / CEO",
        imageUrl: amy,
        linkedinUrl: "https://www.linkedin.com/in/amywanday/",
      },
      {
        name: "Emmanuel Obam",
        role: "General Manager",
        imageUrl: obam,
        linkedinUrl:
          "https://www.linkedin.com/in/emmanuel-obam-aciarb-6b5556125/",
      },
      {
        name: "Cyprian Kimutai",
        role: "Communications Manager",
        imageUrl: cyprian,
        linkedinUrl: "https://www.linkedin.com/in/cypriankimutai/",
      },
    ],
  },
  projects: {
    posts: [
      {
        title: "Youth Football Clinic",
        href: "https://www.facebook.com/media/set/?set=a.1326357507520617&type=3",
        category: {
          name: "Event",
          href: "https://www.facebook.com/media/set/?set=a.1326357507520617&type=3",
        },
        description:
          "Equipping Kenyan footballers with skills to build their brands as aspiring footballers.",
        date: "Feb 12, 2020",
        datetime: "2020-02-12",
        imageUrl: youthClinicImage,
        },
      {
        title: "Sports for Development & Peace Summit",
        href: "https://www.youtube.com/channel/UCwy9zRRyRdlx06Yhv4Q0MFg/videos",
        category: {
          name: "Online event",
          href: "https://www.youtube.com/channel/UCwy9zRRyRdlx06Yhv4Q0MFg/videos",
        },
        description:
          "Enhancing skillsets and learning from 20+ experts in the sports industry globally.",
        date: "Mar 16, 2020",
        datetime: "2020-03-16",
        imageUrl:
         summitImage,
      },
      {
        title: "Sports Leadership Workshop",
        href: "https://www.facebook.com/media/set/?set=a.1072858942870476&type=3",
        category: {
          name: "Workshop",
          href: "https://www.facebook.com/media/set/?set=a.1072858942870476&type=3",
        },
        description:
          "'Promoting youth leadership in sports' Encouraging and equipping Kenyan sports people with skills to venture into the sports industry.",
        date: "Feb 12, 2020",
        datetime: "2020-02-12",
        imageUrl: sportsLeadershipImage,
      },
      {
        title: "African Sports Festival",
        href: "https://www.facebook.com/media/set/?set=a.955057154650656&type=3",
        category: {
          name: "Event",
          href: "https://www.facebook.com/media/set/?set=a.955057154650656&type=3",
        },
        description:
          "Celebrating the international day of sport for development and peace.",
        date: "Feb 12, 2020",
        datetime: "2020-02-12",
        imageUrl: africanSportsFestivalImage,
      },

      {
        title: "African Sports Business Forum",
        href: "https://www.facebook.com/media/set/?set=a.744114549078252&type=3",
        category: {
          name: "Workshop",
          href: "https://www.facebook.com/media/set/?set=a.744114549078252&type=3",
        },
        description:
          "A two day sports business forum in Johannesburg focusing on sports talks and boot camps to enhance youth sports entrepreneurship.",
        date: "Feb 12, 2020",
        datetime: "2020-02-12",
        imageUrl: africanSportsBusinessForumImage,
      },

      {
        title: "Urban Cup: Women Rising",
        href: "https://www.facebook.com/media/set/?set=a.963985143757857&type=3",
        category: {
          name: "Event",
          href: "https://www.facebook.com/media/set/?set=a.963985143757857&type=3",
        },
        description:
          "A platform for female footballers to gain experience and exposure.",
        date: "Mar 10, 2020",
        datetime: "2020-03-10",
        imageUrl: UrbanCupImage,
      },
    ],
  },
  services: {
    supportLinks: [
      {
        name: "Event Management",
        href: "#",
        description: (
          <p>
            <strong className="text-red-500">Managing</strong> every detail
            involved in preparing for sporting events to guarantee that
            <strong className="text-red-500"> every aspect</strong> of the
            sporting event is under control. Our work ranges from community
            soccer and basketball tournaments to cycling events.
          </p>
        ),
        icon: SparklesIcon,
      },
      {
        name: "Sports Marketing",
        href: "#",
        description: (
          <p>
            <strong className="text-red-500">Promotion</strong> of sporting
            events, teams, and players, as well as a variety of{" "}
            <strong className="text-red-500">products and services</strong>{" "}
            connected to those teams.Social media focus mainly through Facebook,
            Instagram and Twitter.
          </p>
        ),
        icon: TrendingUpIcon,
      },
      {
        name: "Sports Talks",
        href: "#",
        description: (
          <p>
            These <strong className="text-red-500">talks</strong> focus on
            sensitization and awareness on the importance of sports{" "}
            <strong className="text-red-500">entrepreneurship</strong>. They
            push attendees to take action and be{" "}
            <strong className="text-red-500">solution focused</strong> rather
            than simply discuss the challenges in the industry.
          </p>
        ),
        icon: SpeakerphoneIcon,
      },
      {
        name: "Sports Consultancy",
        href: "#",
        description: (
          <p>
            Recommendations for{" "}
            <strong className="text-red-500">success</strong>, proposal
            rectification and execution of any details as requested. The African
            Sports Network has experience assisting{" "}
            <strong className="text-red-500">sports federations</strong> and
            organizations plan and execute company and sponsorship strategies.
          </p>
        ),
        icon: BriefcaseIcon,
      },
      {
        name: "Sports Entrepreneurial Leadership Workshops",
        href: "#",
        description: (
          <p>
            Creating <strong className="text-red-500">opportunities</strong> for
            young leaders to identify and address{" "}
            <strong className="text-red-500">social challenges</strong> in the
            sports industry and begin to see opportunities for job creation for
            youth through{" "}
            <strong className="text-red-500">sports enterprise</strong>.
          </p>
        ),
        icon: PresentationChartLineIcon,
      },
    ],
  },
  contact: {
    offices: [
      {
        id: 1,
        city: "Nairobi",
        address: ["4556 Brendan Ferry", "Los Angeles, CA 90210"],
      },
    ],
  },
};
