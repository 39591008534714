import React from "react";
import { staticContent } from "../../../utils/staticContent/static";
import homepageImage from "../../../utils/staticContent/images/home/homepage1.png";
import amy from "../../../utils/staticContent/images/home/homepage2.jpg";
const LandingPage = () => {
  const { stats } = staticContent.home;
  return (
    <>
      <div className="bg-gray-50">
        <div className="relative overflow-hidden">
          <div className="absolute inset-y-0 h-full w-full" aria-hidden="true">
            <div className="relative h-full">
              <svg
                className="absolute right-full transform translate-y-1/3 translate-x-1/4 md:translate-y-1/2 sm:translate-x-1/2 lg:translate-x-full"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="e229dbec-10e9-49ee-8ec3-0286ca089edf"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={784}
                  fill="url(#e229dbec-10e9-49ee-8ec3-0286ca089edf)"
                />
              </svg>
              <svg
                className="absolute left-full transform -translate-y-3/4 -translate-x-1/4 sm:-translate-x-1/2 md:-translate-y-1/2 lg:-translate-x-3/4"
                width={404}
                height={784}
                fill="none"
                viewBox="0 0 404 784"
              >
                <defs>
                  <pattern
                    id="d2a68204-c383-44b1-b99f-42ccff4e5365"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={784}
                  fill="url(#d2a68204-c383-44b1-b99f-42ccff4e5365)"
                />
              </svg>
            </div>
          </div>

          <div className="mt-16 mx-auto max-w-7xl mb-20 px-4 sm:mt-24 sm:px-6">
            <div className="text-center">
              <h1 className="text-4xl tracking-tight font-extrabold text-gray-900 sm:text-5xl md:text-6xl">
                <span className="block">
                  Increasing Engagement and Awareness
                </span>
                <span className="block text-red-600">
                  in the Sports Industry.
                </span>
              </h1>
            </div>
          </div>

          <div className="relative mb-20 lg:mb-1">
            <div className="absolute inset-0 flex flex-col" aria-hidden="true">
              <div className="flex-1" />
              <div className="flex-1 w-full bg-gray-900" />
            </div>
            <div className="max-w-7xl mx-auto px-4 sm:px-6">
              <img
                className="relative rounded-lg shadow-lg"
                src={homepageImage}
                alt="App screenshot"
              />
            </div>
          </div>
        </div>
      </div>
      <div className="relative bg-white -py-1 sm:py-24">
        <div className="lg:mx-auto lg:max-w-7xl lg:px-8  sm:mt-20 lg:grid lg:grid-cols-2 lg:gap-24 lg:items-start">
          <div className="relative sm:py-16 lg:py-0">
            <div
              aria-hidden="true"
              className="hidden sm:block lg:absolute lg:inset-y-0 lg:right-0 lg:w-screen"
            >
              <div className="absolute inset-y-0 right-1/2 w-full bg-gray-50 rounded-r-3xl lg:right-72" />
              <svg
                className="absolute top-8 left-1/2 -ml-3 lg:-right-8 lg:left-auto lg:top-12"
                width={404}
                height={392}
                fill="none"
                viewBox="0 0 404 392"
              >
                <defs>
                  <pattern
                    id="02f20b47-fd69-4224-a62a-4c9de5c763f7"
                    x={0}
                    y={0}
                    width={20}
                    height={20}
                    patternUnits="userSpaceOnUse"
                  >
                    <rect
                      x={0}
                      y={0}
                      width={4}
                      height={4}
                      className="text-gray-200"
                      fill="currentColor"
                    />
                  </pattern>
                </defs>
                <rect
                  width={404}
                  height={392}
                  fill="url(#02f20b47-fd69-4224-a62a-4c9de5c763f7)"
                />
              </svg>
            </div>
            <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0 lg:max-w-none lg:py-20">
              {/* Testimonial card*/}
              <div className="relative pt-64 pb-10 rounded-2xl shadow-xl overflow-hidden">
                <img
                  className="absolute inset-0 h-full w-full object-cover"
                  src={amy}
                  alt=""
                />

                <div className="absolute inset-0 bg-red-300 mix-blend-multiply" />
                <div className="absolute inset-0 bg-gradient-to-t from-red-500 opacity-90" />
                <div className="relative px-8">
                  <blockquote className="mt-8">
                    <div className="relative text-lg font-medium text-white md:flex-grow">
                      <svg
                        className="absolute top-0 left-0 transform -translate-x-3 -translate-y-2 h-8 w-8 text-red-400"
                        fill="currentColor"
                        viewBox="0 0 32 32"
                        aria-hidden="true"
                      >
                        <path d="M9.352 4C4.456 7.456 1 13.12 1 19.36c0 5.088 3.072 8.064 6.624 8.064 3.36 0 5.856-2.688 5.856-5.856 0-3.168-2.208-5.472-5.088-5.472-.576 0-1.344.096-1.536.192.48-3.264 3.552-7.104 6.624-9.024L9.352 4zm16.512 0c-4.8 3.456-8.256 9.12-8.256 15.36 0 5.088 3.072 8.064 6.624 8.064 3.264 0 5.856-2.688 5.856-5.856 0-3.168-2.304-5.472-5.184-5.472-.576 0-1.248.096-1.44.192.48-3.264 3.456-7.104 6.528-9.024L25.864 4z" />
                      </svg>
                      <p className="relative">
                        We hope that in the next 10 years, our impact will be
                        visible through the increased numbers of young Africans
                        taking up leadership positions in the sports industry
                        and creating positive impacts in their communities.
                      </p>
                    </div>

                    <footer className="mt-4">
                      <p className="text-base font-semibold text-red-200">
                        Amy Wanday, CEO at African sports Network
                      </p>
                    </footer>
                  </blockquote>
                </div>
              </div>
            </div>
          </div>

          <div className="relative mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-0">
            {/* Content area */}
            <div className="pt-12 sm:pt-16 lg:pt-20">
              <h2 className="text-3xl text-gray-900 font-extrabold tracking-tight sm:text-4xl">
                On a mission to uplift the African sports industry
              </h2>
              <div className="mt-6 text-gray-500 space-y-6">
                <p className="text-base leading-7 text-justify">
                  Sports can be used to tackle social issues ranging from drug
                  abuse to unemployment, and one of the major beliefs we have is
                  that sports activities are a great tool of emancipation of
                  women in a society. Involving girls in sports activities
                  alongside boys can help overcome prejudice that often
                  contributes to social vulnerability of women and girls.
                </p>
                <p className="text-base leading-7 text-justify">
                  When a woman excels in sports, she is a catalyst for
                  challenging gender stereotypes and she promotes and empowers
                  other women to rise up. We aim to ensure that no young,
                  passionate and ambitious African child aspires to join the
                  sports industry and is pressured into believing that it is not
                  a viable career path. Most importantly, we want to guarantee
                  that no young girl with a love for sports feels limited in the
                  industry due to her gender. The African Sports Network is
                  positioned to give African youth an upright support system to
                  enable them to excel in school, while at the same time working
                  towards transforming Africa’s sports industry.
                </p>
              </div>
            </div>

            {/* Stats section */}
            <div className="mt-10">
              <dl className="grid grid-cols-2 gap-x-4 gap-y-8">
                {stats.map((stat) => (
                  <div
                    key={stat.label}
                    className="border-t-2 border-gray-100 pt-6"
                  >
                    <dt className="text-base font-medium text-gray-500">
                      {stat.label}
                    </dt>
                    <dd className="text-3xl font-extrabold tracking-tight text-gray-900">
                      {stat.value}
                    </dd>
                  </div>
                ))}
              </dl>
              <div className="mt-10">
                <a
                  href="https://www.ted.com/talks/amy_wanday_shaping_the_future_of_sport_in_africa"
                  className="text-base z-50 mt-20 font-medium text-red"
                >
                  {" "}
                  Watch Shaping the future of Sport in Africa |{" "}
                  <strong className="text-red-500">TEDx </strong>
                  <span aria-hidden="true">&rarr;</span>{" "}
                </a>{" "}
                <br />
                <a
                  href="/aboutus"
                  className="text-base font-medium text-red-600"
                >
                  {" "}
                  Learn more about how we're changing the sports industry{" "}
                  <span aria-hidden="true">&rarr;</span>{" "}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
