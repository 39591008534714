import React from 'react'
import {useSelector} from 'react-redux'
import { InformationCircleIcon } from '@heroicons/react/solid'

const Alerts = () => {

  const alertsList = useSelector(state => state.alert);

  return (
    alertsList.length > 0 && 
    alertsList.map(alert => (
      <div className="rounded-md bg-blue-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <InformationCircleIcon className="h-5 w-5 text-blue-400" aria-hidden="true" />
        </div>
        <div className="ml-3 flex-1 md:flex md:justify-between">
          <p className="text-sm text-blue-700">{alert.message}</p>
          <p className="mt-3 text-sm md:mt-0 md:ml-6">
            <a href="#" className="whitespace-nowrap font-medium text-blue-700 hover:text-blue-600">
              Details <span aria-hidden="true">&rarr;</span>
            </a>
          </p>
        </div>
      </div>
    </div>
    ))
  )
}

export default Alerts